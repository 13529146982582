<template xmlns="">
	<div>
		<!--Header-->
		<BlogHeader />

		<!--Featured-->
		<BlogFeatured />

		<!--Latest Articles-->
		<BlogListOfArticles v-if="articles" :articles="articles.data">
			Read the Latest Articles:
		</BlogListOfArticles>>
	</div>
</template>

<script>
	import BlogListOfArticles from "../components/blog/BlogListOfArticles";
	import BlogFeatured from "../components/blog/BlogFeatured";
	import BlogHeader from "../components/blog/BlogHeader";
	import axios from "axios";

	export default {
		name: 'Blog',
		components: {
			BlogListOfArticles,
			BlogFeatured,
			BlogHeader,
		},
		data() {
			return {
				articles: null,
			}
		},
		mounted() {
			axios.get('https://blog.optiomat.com/api/blogs?filters[featured][$eq]=false&populate=*')
				.then((response) => this.articles = response.data)
				.catch((error) => alert('Sadly, there is an error :(('))
		}
	}
</script>
